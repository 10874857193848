(function($){

	"use strict";

	$(function(){
		 window.GaleryPillar = {};

		/**
		 * [init description]
		 *
		 * @return {[type]}
		 */
		GaleryPillar.init = function ()
		{
			/**
			 * Contenedor donde se pone la imagen
			 * @type jQuery Wrapped DOM Element
			 */
			this.$container = $('#galery-pillar-photos .modal-image');

			/**
			 * Contador que indica el indice de la imagen que estamos viendo
			 * @type integer
			 */
			this.current = 0;

			/**
			 * Total de imagenes en el lightbox
			 * @type integer
			 */
			this.totalImages = $('.pillar-photos .image-pilar').length;


			/**
			 * Load the first image in the GaleryPillar.
			 */
			this.getCurrentImage().load();

		};

		/**
		 * [getCurrentImage description]
		 *
		 * @return {[type]}
		 */
		GaleryPillar.getCurrentImage = function ()
		{
			var image = $('.pillar-photos .image-'+this.current+' img').attr('src');
			this.currentImage = '<img src="'+image+'" >';
			return this;
		};

		/**
		 * [getNextImage description]
		 *
		 * @return {[type]}
		 */
		GaleryPillar.getNextImage = function ()
		{
			var currentnew = parseInt(this.current) + parseInt(1);
			var current = this.getIndex(currentnew);
			this.current = current;
			var image = $('.pillar-photos .image-'+current+' img').attr('src');
			this.currentImage = '<img src="'+image+'" >';
			return this;
		};

		/**
		 * [getPrevImage description]
		 *
		 * @return {[type]}
		 */
		GaleryPillar.getPrevImage = function ()
		{
			var current = this.getIndex(this.current - 1);
			this.current = current;
			var image = $('.pillar-photos .image-'+current+' img').attr('src');

			this.currentImage = '<img src="'+image+'" >';
			return this;
		};

		/**
		 * [getIndex description]
		 *
		 * @param  {[type]} direccion [description]
		 * @return {[type]}
		 */
		GaleryPillar.getIndex = function (current)
		{
			if ( current > (this.totalImages - 1)  )
				current = 0;

			if ( current < 0 )
				current = this.totalImages - 1;

			return current;
		};

		/**
		 * [load description]
		 *
		 * @return {[type]}
		 */
		GaleryPillar.load = function ()
		{
			// Vaciar el contendor
			this.$container.empty();

			// Mostrar la imagen
			this.$container.append(this.currentImage);
		};

		/**
		 * [getThumbnail description]
		 *
		 * @return {[type]}
		 */
		GaleryPillar.getThumbnail = function ()
		{
			return this.currentImage;
		};

		GaleryPillar.init();
		$('.next').on('click', function (event) {
			event.preventDefault();
			GaleryPillar.getNextImage().load();
		});

		$('.prev').on('click', function (e) {
			event.preventDefault();
			GaleryPillar.getPrevImage().load();
		});

		$(document).on('click', '.modal .close', function(event){
			event.preventDefault();
			$('#galery-pillar-photos').css({'display': 'none'});
		});

		$(document).on('click', '.pillar-photos .image-pilar', function () {
			GaleryPillar.current = $(this).attr('data-current');
			GaleryPillar.totalImages = $('.pillar-photos .image-pilar').length;
			GaleryPillar.getCurrentImage().load();
			$('#galery-pillar-photos').css({'display': 'flex'});
		});
	});
})(jQuery);
